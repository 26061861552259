import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import { LocalizationType } from "app/constants/localizationConst";

const selectedLanguage =
  localStorage.getItem(LocalizationType.SELECTED_LANG) ||
  LocalizationType.INITIAL_LANG;

i18next
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    fallbackLng: selectedLanguage,
    load: "languageOnly", // Only load the main language (e.g., 'en'), not the region-specific version (e.g., 'en-EN')
    backend: {
      loadPath: "/locales/{{lng}}.json", // Path to translation files
    },
    lng: selectedLanguage,
    interpolation: {
      escapeValue: false,
    },
    debug: true,
  });

export default i18next;
