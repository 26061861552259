import React, { FC } from "react";
import { LanguageDef } from "app/types/geographyTypes";

type LanguageContextState = {
  availableLanguages: LanguageDef[];
  children: React.ReactNode;
};

const LanguageContext = React.createContext([] as LanguageDef[]);

const LanguageProvider: FC<LanguageContextState> = ({
  availableLanguages,
  children,
}) => {
  return (
    <LanguageContext.Provider value={availableLanguages}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
