import { create } from "zustand";
import { persist } from "zustand/middleware";
import { LocalizationType } from "app/constants/localizationConst";

type Localization = {
  selectedLanguage: string;
  handleSelectedLanguage: (data: string) => void;
};

/**
 * State Management for localization
 * @dynamic
 * @hook {function}
 */
export const useLocalization = create(
  persist<Localization>(
    set => ({
      selectedLanguage: LocalizationType.INITIAL_LANG,
      handleSelectedLanguage: (data: string) => {
        set(() => ({
          selectedLanguage: data,
        }));
      },
      clearAll: () =>
        set({
          selectedLanguage: LocalizationType.INITIAL_LANG,
        }),
    }),
    {
      name: "localization-storage",
    }
  )
);
