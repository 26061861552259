import React, { FC } from "react";
import { GeographyCountryDef } from "app/types/geographyTypes";

type CountriesContextState = {
  countries: GeographyCountryDef[];
  children: React.ReactNode;
};

const CountriesContext = React.createContext([] as GeographyCountryDef[]);

const CountriesProvider: FC<CountriesContextState> = ({
  countries,
  children,
}) => {
  return (
    <CountriesContext.Provider value={countries}>
      {children}
    </CountriesContext.Provider>
  );
};

export { CountriesContext, CountriesProvider };
