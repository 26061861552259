import React, { Suspense, useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Router from "app/routers/Router";
import { UserProvider } from "app/store/contexts/userContext";
import { LanguageProvider } from "app/store/contexts/languageContext";
import { CountriesProvider } from "app/store/contexts/countriesContext";
import Loading from "app/components/Loading/Loading";
import languages from "app/localization/languages.json";
import allCountries from "app/localization/geo-countries.json";
import "app/localization/localization";
import "./index.scss";
import { GeographyCountryDef, LanguageDef } from "app/types/geographyTypes";

const App = () => {
  const [availableLanguages, setAvailableLanguages] = useState<LanguageDef[]>(
    []
  );
  const [countries, setCountries] = useState<GeographyCountryDef[]>([]);

  useEffect(() => {
    setAvailableLanguages(languages);
    setCountries(allCountries);
  }, [countries]);

  return (
    <Suspense fallback={<Loading isFullscreen />}>
      <CountriesProvider countries={countries}>
        <LanguageProvider availableLanguages={availableLanguages}>
          <UserProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </UserProvider>
        </LanguageProvider>
      </CountriesProvider>
    </Suspense>
  );
};

const rootElement = document.getElementById("root");

if (!rootElement) throw new Error("Couldn't find element with id root");

const root = createRoot(rootElement);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
